import UAParser from "ua-parser-js";

export const uaParserInstance = new UAParser();

export function isMobile(): boolean {
  return !window.matchMedia("(min-width: 992px)").matches;
}

export function isTablet(): boolean {
  const device = uaParserInstance.getDevice();
  return (
    (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
    device.type === UAParser.DEVICE.TABLET
  );
}
