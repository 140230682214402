import React from "react";
import { ReactComponent as CloseIcon } from "../../../icons/close.svg";

export type CloseButtonProps = {
  id?: string;
  onClick: () => void;
};

function CloseButton({ id = undefined, onClick }: CloseButtonProps) {
  return (
    <div id={id} className="button" onClick={onClick}>
      <CloseIcon className="full-size" />
    </div>
  );
}

export default CloseButton;
