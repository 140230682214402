export enum SupportedVideoFormat {
  AV1,
  WEBM,
  HEVC,
  H264,
}

export const videoFormats = [
  SupportedVideoFormat.AV1, // working on chrome desktop
  SupportedVideoFormat.HEVC, // working on safari
  SupportedVideoFormat.WEBM, // working on chrome desktop
  SupportedVideoFormat.H264, // works on all browsers
];

export function getVideoFileExtension(format: SupportedVideoFormat): string {
  switch (format) {
    case SupportedVideoFormat.AV1:
      return ".av1.mp4";
    case SupportedVideoFormat.WEBM:
      return ".vp9.webm";
    case SupportedVideoFormat.HEVC:
      return ".hevc.mp4";
    case SupportedVideoFormat.H264:
      return ".mp4"; // codecs=avc1.4D401E,mp4a.40.2";
  }
}

export function getVideoType(format: SupportedVideoFormat): string {
  switch (format) {
    case SupportedVideoFormat.AV1:
      return "video/mp4; codecs=av01.0.05M.08,opus";
    case SupportedVideoFormat.WEBM:
      return "video/webm; codecs=vp9";
    case SupportedVideoFormat.HEVC:
      return "video/mp4; codecs=hvc1";
    case SupportedVideoFormat.H264:
      return "video/mp4; codecs=avc1.4D401E,mp4a.40.2";
  }
}

export enum SupportedImageFormat {
  AVIF,
  WEBP,
  JPG,
  PNG,
}

export const imageFormats = [
  SupportedImageFormat.AVIF, // only working on chrome desktop
  SupportedImageFormat.WEBP, // works on all browsers
  SupportedImageFormat.JPG, // works on all browsers
  SupportedImageFormat.PNG, // works on all browsers
];

export function getImageFileExtension(format: SupportedImageFormat): string {
  switch (format) {
    case SupportedImageFormat.AVIF:
      return ".avif";
    case SupportedImageFormat.WEBP:
      return ".webp";
    case SupportedImageFormat.JPG:
      return ".jpg";
    case SupportedImageFormat.PNG:
      return ".png";
  }
}

export function getImageType(format: SupportedImageFormat): string {
  switch (format) {
    case SupportedImageFormat.AVIF:
      return "image/avif";
    case SupportedImageFormat.WEBP:
      return "image/webp";
    case SupportedImageFormat.JPG:
      return "image/jpeg";
    case SupportedImageFormat.PNG:
      return "image/png";
  }
}
